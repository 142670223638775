import React, { useEffect, useState } from "react";
import { Navbar } from "../../Components/Navbar";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import Card from '@mui/material/Card';
import SearchIcon from '@mui/icons-material/Search';
import { useHistory } from "react-router-dom";
import { useParams } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { getBCPresets } from "../../redux/actions/BookClosure/actionBookClosure";
import { getOneBookClosure, importTaskFormPreset } from "../Services/ApiHandler";
import swal from "sweetalert";
import { toast } from "react-toastify";
import Loader from "../../helper/loader";


const initialState = {
    presetID: "",
    isPresetChecked: false,
    tasks: false,
    assignee: false,
    approver: false,
    template: false,
    templateAmount: false,
    FSCPEntries: false,
    FSCPEntriesAmount: false
}

const ImportPreset = () => {
    const history = useHistory();
    const dispatch = useDispatch();
    const { workspaceId, companyId } = useParams();

    const {
        presets,
        loading,
        errorMessage,
    } = useSelector((state) => state.BookClosurePresetReducer);

    const {
        bookClosureId,
    } = useSelector((state) => state.BookClosureReducer);

    const [dataForImport, setDataForImport] = useState(initialState);

    useEffect(() => {
        if (!bookClosureId) {
            getBookClosureData();
        }
    }, []);

    useEffect(() => {
        dispatch(getBCPresets(1));
    }, [dispatch]);

    const getBookClosureData = () => {
        const data = {
            companies: [companyId],
        }
        getOneBookClosure(workspaceId, data).then(response => {
            dispatch({ type: "BOOK_cLOSURE_DATA", payload: response.data.bookClosure || {} });
        }).catch(error => {
            swal("Error", `Book Closure Error: ${error.response?.data?.error}`, 'error');
        })
    }

    const onChecked = (e) => {
        const { name, value } = e.target;
        setDataForImport(prev => ({
            ...prev,
            [name]: !dataForImport[name],
        }));
    }

    const ImportPresetFromTask = () => {
        const data = {
            // required keys
            "bookClosureID": bookClosureId,
            "companyID": companyId,
            "presetID": dataForImport.presetID,
            // optional keys
            "tasks": dataForImport.tasks,
            "assignee": dataForImport.assignee,
            "approver": dataForImport.approver,
            "template": dataForImport.template,
            "templateAmount": dataForImport.templateAmount,
            "FSCPEntries": dataForImport.FSCPEntries,
            "FSCPEntriesAmount": dataForImport.FSCPEntriesAmount
        }

        importTaskFormPreset(data).then(response => {
            toast.success("Preset Imported Successfully.");
            setDataForImport(initialState);
            history.push(`/bookclosure/${workspaceId}`);
        }).catch(error => {
            swal("Error", error.response?.data?.error || "Import Task Failed!", 'error');
        })

    }

    return (
        <>
            <Navbar />
            {
                loading ? <Loader /> :
                errorMessage ? <p className="w-100 text-center text-danger">{errorMessage}</p> :
                (
                    <div className="px-5 py-4">
                        <div className="d-flex align-items-center">
                            <span><ArrowBackIcon className="custom-common-theme-text-color" /></span>
                            <p className="mb-0 mt-0 ml-2 custom-common-theme-text-color font-size-16 custom-font-600font-sans cursor-pointer" onClick={() => history.push(`/bookclosure/${workspaceId}`)} >Back</p>
                        </div>
                        <div className="d-flex justify-content-between mt-4">
                            <div>
                                <p className="font-size-24 custom-font-600 font-sans">Import Preset</p>
                                <p className="text-secondary font-size-16 custom-font-500 font-sans">Some text to give the user Info about Import Preset</p>
                            </div>
                            <div>
                                <div className="search-btnv2 d-flex flex-row jusify-content-center align-items-center mt-3">
                                    <span>
                                        <SearchIcon style={{ color: "gray" }} />
                                    </span>
                                    <input
                                        className="border-0 mt-0 p-0"
                                        type="text"
                                        //   value={search}
                                        //   onChange={handleChange}
                                        placeholder="Search by preset name..."
                                    />
                                </div>
                            </div>
                        </div>
                        <div>
                            <p className="custom-font-600 font-size-18 font-sans">All presets</p>
                        </div>
                        <div className="d-flex" style={{ gap: "10px" }}>
                            <div className="w-100">
                                {
                                    presets && presets.length > 0 && presets.map((preset, index) => (
                                        <Card style={{ borderRadius: "15px" }} key={index}>
                                            <div className="d-flex justify-content-center align-items-center " style={{ backgroundColor: "#E5EEEE", height: "60px" }}>
                                                <input
                                                    type="checkbox"
                                                    checked={dataForImport.isPresetChecked}
                                                    style={{ width: "23px", height: "23px" }}
                                                    onClick={() => {
                                                        setDataForImport(prev => ({
                                                            ...prev,
                                                            isPresetChecked: !dataForImport.isPresetChecked,
                                                            presetID: !dataForImport.isPresetChecked ? preset?._id : ""
                                                        }));
                                                    }}
                                                />
                                                <p className="mt-0 mb-0 font-size-18 custom-font-600 font-inter">{preset?.name}</p>
                                            </div>
                                            <div className="px-3">
                                                <p className="font-size-16 custom-font-600 font-sans">Import</p>
                                            </div>
                                            <div className="d-flex" style={{ gap: "60px" }}>
                                                <div className="form-check">
                                                    <input
                                                        className="form-check-input"
                                                        type="checkbox"
                                                        checked={dataForImport.tasks}
                                                        name="tasks"
                                                        id="flexCheckDefault"
                                                        style={{ width: "23px", height: "23px" }}
                                                        onClick={onChecked}
                                                    />
                                                    <label className="form-check-label font-sans" for="flexCheckDefault" style={{ marginLeft: "32px", padding: "8px" }}>
                                                        Tasks
                                                    </label>
                                                </div>
                                                <div className="form-check">
                                                    <input
                                                        className="form-check-input"
                                                        type="checkbox"
                                                        checked={dataForImport.template}
                                                        name="template"
                                                        id="flexCheckDefault"
                                                        style={{ width: "23px", height: "23px" }}
                                                        onClick={onChecked}
                                                    />
                                                    <label className="form-check-label font-sans" for="flexCheckDefault" style={{ marginLeft: "32px", padding: "8px" }}>
                                                        Templates
                                                    </label>
                                                </div>
                                                <div className="form-check">
                                                    <input
                                                        className="form-check-input"
                                                        type="checkbox"
                                                        checked={dataForImport.FSCPEntries}
                                                        name="FSCPEntries"
                                                        onClick={onChecked}
                                                        id="flexCheckDefault"
                                                        style={{ width: "23px", height: "23px" }}
                                                    />
                                                    <label className="form-check-label font-sans" for="flexCheckDefault" style={{ marginLeft: "32px", padding: "8px" }}>
                                                        FSCP Entries
                                                    </label>
                                                </div>
                                            </div>
                                            <div className="d-flex ml-3" style={{ gap: "50px" }}>
                                                {/* <div className="form-check">
                                            <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault" style={{ width: "23px", height: "23px" }} />
                                            <label className="form-check-label font-sans" for="flexCheckDefault" style={{ marginLeft: "32px", padding: "8px" }}>
                                                Sub tasks
                                            </label>
                                        </div> */}
                                                <div className="form-check">
                                                    <input
                                                        className="form-check-input"
                                                        type="checkbox"
                                                        style={{ width: "23px", height: "23px" }}
                                                        checked={dataForImport.templateAmount}
                                                        name="templateAmount"
                                                        onClick={onChecked}
                                                    />
                                                    <label className="form-check-label font-sans" for="flexCheckDefault" style={{ marginLeft: "32px", padding: "8px" }}>
                                                        with amount
                                                    </label>
                                                </div>
                                                <div className="form-check">
                                                    <input
                                                        className="form-check-input"
                                                        type="checkbox"
                                                        style={{ width: "23px", height: "23px" }}
                                                        checked={dataForImport.FSCPEntriesAmount}
                                                        name="FSCPEntriesAmount"
                                                        onClick={onChecked}
                                                    />
                                                    <label className="form-check-label font-sans" for="flexCheckDefault" style={{ marginLeft: "32px", padding: "8px" }}>
                                                        with amount
                                                    </label>
                                                </div>
                                            </div>
                                            <div className="d-flex ml-3" style={{ gap: "60px" }}>
                                                <div className="form-check">
                                                    <input
                                                        className="form-check-input"
                                                        type="checkbox"
                                                        style={{ width: "23px", height: "23px" }}
                                                        checked={dataForImport.assignee}
                                                        name="assignee"
                                                        onClick={onChecked}
                                                    />
                                                    <label className="form-check-label font-sans" for="flexCheckDefault" style={{ marginLeft: "32px", padding: "8px" }}>
                                                        Assignee
                                                    </label>
                                                </div>

                                            </div>
                                            <div className="d-flex ml-3 mb-3" style={{ gap: "60px" }}>
                                                <div className="form-check">
                                                    <input
                                                        className="form-check-input"
                                                        type="checkbox"
                                                        style={{ width: "23px", height: "23px" }}
                                                        checked={dataForImport.approver}
                                                        name="approver"
                                                        onClick={onChecked}
                                                    />
                                                    <label className="form-check-label font-sans" for="flexCheckDefault" style={{ marginLeft: "32px", padding: "8px" }}>
                                                        Approver
                                                    </label>
                                                </div>
                                            </div>
                                        </Card>
                                    ))
                                }
                            </div>
                        </div>
                        <div className="d-flex justify-content-center mt-5 import-btnv1" >
                            <button onClick={ImportPresetFromTask} className="custom-common-theme-bg-color text-white radius-6px font-sans btnv1-imort font-sans ">Import</button>
                        </div>
                    </div >
                )
            }
        </>
    );
};

export default ImportPreset;
