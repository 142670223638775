import React, { useEffect, useState } from 'react';
import { MatrixNav } from './MatrixNav';
import { useParams } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { getOneBookClosure } from '../Services/ApiHandler';
import swal from "sweetalert";
import { getBCMatrix } from '../../redux/actions/BookClosure/actionBookClosure';
import ViewMatrixTasks from '../Components/ViewMatrixTasks';

const Matrix = () => {
    const { workspaceId, companyId } = useParams();
    const dispatch = useDispatch();

    const {
        bookClosureId,
    } = useSelector((state) => state.BookClosureReducer);

    const {
        matrices,
        bcmLoading,
        bcmErrorMessage,
        bcmApiFetched
    } = useSelector((state) => state.BookClosureReducer);

    const [isViewModule, setIsViewModule] = useState(false);
    const [moduleDetails, setModuleDetails] = useState({});

    useEffect(() => {
        getBookClosureData();
        if (!bcmApiFetched) {
            dispatch(getBCMatrix(bookClosureId, companyId));
        }
    }, [dispatch]);

    const getBookClosureData = () => {
        const data = {
            // companies: [],
            // assignees: [],
            // approvers: [userId]
        }
        if (!bookClosureId) {
            getOneBookClosure(workspaceId, data).then(response => {
                dispatch({ type: "BOOK_cLOSURE_DATA", payload: response.data.bookClosure || {} });
                const book_closure_id = response.data.bookClosure._id;
                dispatch(getBCMatrix(book_closure_id, companyId));
            }).catch(error => {
                swal("Error", `Book Closure Error: ${error.response?.data?.error}`, 'error');
            })
        }
    }

    return (
        <>
            <MatrixNav />
            <div>
                {
                    bcmLoading ? <p>Loading ....</p> :
                        bcmErrorMessage ? <p className='text-danger' > {bcmErrorMessage} </p> :
                            matrices ?
                                <>
                                    <table className="table table-striped">
                                        <thead>
                                            <tr  style={{ backgroundColor:" #F8F8F8" }}>
                                                <th scope="col" className='text-left text-uppercase text-secondary font-size-14 custom-font-600 font-inter'  >S NO</th>
                                                <th scope="col" className='text-left text-uppercase text-secondary font-size-14 custom-font-600 font-inter'  >Ledger Code</th>
                                                <th scope="col" className='text-left text-uppercase text-secondary font-size-14 custom-font-600 font-inter'  >Ledger Name</th>
                                                <th scope="col" className='text-left text-uppercase text-secondary font-size-14 custom-font-600 font-inter'  >Amount</th>
                                                <th scope="col" className='text-left text-uppercase text-secondary font-size-14 custom-font-600 font-inter'  >Modules</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {matrices?.length > 0 && matrices?.map((matrix, mx) => (
                                                <tr className='bg-white' key={mx}>
                                                    <td className=' font-size-16 custom-font-500'>{mx + 1}</td>
                                                    <td className=' font-size-16 custom-font-500'>{matrix?.ledgerCode}</td>
                                                    <td className=' font-size-16 custom-font-500'>{matrix?.ledgerName}</td>
                                                    <td className=' font-size-16 custom-font-500'>{matrix?.finalLedgerAmount}</td>
                                                    <td className=' font-size-16 custom-font-500'>
                                                        {
                                                            matrix?.modules && matrix?.modules.length > 0 &&
                                                            matrix?.modules.map((module, index) => (
                                                                <button
                                                                    key={index}
                                                                    className='border-0 bg-color-inherit custom-common-theme-text-color'
                                                                    onClick={() => {
                                                                        setIsViewModule(true);
                                                                        setModuleDetails(module);
                                                                    }}
                                                                >
                                                                    {module?.module}
                                                                </button>
                                                            ))
                                                        }
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </>
                                : <></>
                }
            </div>

            <ViewMatrixTasks 
                isViewModule={isViewModule}
                setIsViewModule={setIsViewModule}
                setModuleDetails={setModuleDetails}
                moduleDetails={moduleDetails}
            />
        </>
    );
};

export default Matrix;

