import { useParams } from "react-router-dom";
import Select from "react-select";
import { Submit } from "../../Components/Button";
import arrowLeftGreen from "../../assets/arrowLeftGreen.svg";
import BSModalView from "../Component/modal/bsModalView";
import { getPrepareConsole, getProjects } from "./services/apiHandler";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { consoleNotesData, filterOptionsData } from "./services/mockHandler";
import { fetchGroupData } from "../../redux/actions/actionGrouping";
import { fetchBSData } from "../../redux/actions/actionBS";
import { fetchPNLData } from "../../redux/actions/actionPNL";
import { fetchSocieData } from "../../redux/actions/actionSocie";
import { getAllIR, getCompany } from "../../WorkSpace/Components/ApiServices/apiHandler";
import { fetchPCData } from "../../redux/actions/actionPreset";
import { CircularProgress } from "@material-ui/core";
import { useHistory } from "react-router";
import { fetchAssociateData } from "../../redux/actions/actionAssociate";
import { Navbar } from "../../Components/Navbar";

const header = {
  height: "80px",
  width: "100%",
  backgroundColor: "white",
  display: "flex",
  alignItems: "center",
  padding: "0rem 1rem",
  borderRadius: "3px",
  marginBottom: "3rem",
  marginTop: "40px",
};
const name = {
  fontSize: "15px",
  fontWeight: "500",
  paddingLeft: "1%",
  paddingRight: "2%",
};
const button = {
  padding: "8px",
  width: "10rem",
  // marginLeft:'auto'
};
const button3 = {
  padding: "8px",
  width: "15rem",
  marginLeft: "283px",
};
const button2 = {
  padding: "8px",
  width: "15em",
  marginLeft: "auto",
};
const backNav = {
  fontSize: "1.2rem",
  fontWeight: "600",
  color: "#03565A",
  cursor: "pointer",
};

const mainTag = {
  fontSize: "1.15rem",
  fontWeight: "700",
};
const line = {
  height: "70%",
  width: "2px",
  backgroundColor: "rgb(229, 229, 229)",
  marginRight: "2%",
};
const ViewFS = (props) => {
  const id = useParams();
  const [consoldata, setConsolData] = useState("");
  const [projectPreset, setProjectPreset] = useState(null);
  const [projectsData, setProjectsData] = useState([]);
  const [allcheckdata, setConsolidate] = useState([]);
  const [allAssociateCheckData, setAssociateConsolidate] = useState([]);

  
  const [selectedPresetProject, setSelectedPresetProject] = useState(null);
  //   const AllCheckData = useSelector((state) => state.APAReducer);
  const [filterData, setFilterData] = useState([]);
  const [notes, setNotes] = useState([]);
  const balanceSheet = useSelector((state) => state.BSReducer);
  const pnlStatement = useSelector((state) => state.PNLReducer);
  const associateStatement = useSelector((state) => state.ASSOCIATEReducer);
  const getSocie = useSelector((state) => state.SoccieReducer);
  const groupingData = useSelector((state) => state.GroupingReducer);
  const dispatch = useDispatch();
  const currentCompProjectId = selectedPresetProject?.project_id?selectedPresetProject?.project_id:projectPreset;
  const [allIrList, setAllIr] = useState([]);
  const [allCompany, setAllCompany] = useState([]);
  // const fetchPrepareConsole = useSelector((state) => state.PCReducer);
  let history = useHistory();
  useEffect(() => {
    // dispatch(fetchPCData(id.id));
    AllIrData();
    fetchPreConsole();
    getCompanyData();
    GetCompany();
  }, []);

  const AllIrData = async () => {
    const res = await getAllIR();
    if (res.status === 200) {
      setAllIr(res.data.fetchIR);
    }
  };
  const GetCompany = async () => {
    const res = await getCompany();
    if (res?.status === 200) {
      setAllCompany(res.data.getCompany);
    }
  };
  const FindIr = (ir_id) => {
    const IrData = allIrList.filter((data) => data._id === ir_id);
    return IrData;
  };

  useEffect(() => {
    (async () => {
      if (allcheckdata && allIrList) {
        if (filterData.length === 0) {
          const filter_res = await filterOptionsData();
          setFilterData(filter_res.data);
        }
        if (notes.length === 0) {
          const notes_data = await consoleNotesData();
          setNotes(notes_data.data);
        }
        if (allcheckdata.length > 0) {
          const project_ids = allcheckdata?.map((data) => data.project_id);
          const browse_fs = allcheckdata?.map((data) => FindIr(data.Ir_id));
          const browse_fs_ids = browse_fs?.map(
            (data) => data[0]?.browse_control_dateFS
          );
          if (
            !groupingData.isSuccess ||
            groupingData.set_payload !==
              JSON.stringify({
                project_ids,
                browse_fs_ids,
                currentCompProjectId,
              })
          ) {
            dispatch(
              fetchGroupData(project_ids, browse_fs_ids, currentCompProjectId)
            );
          }
          if (
            !balanceSheet.isSuccess ||
            balanceSheet.set_payload !==
              JSON.stringify({
                project_ids,
                browse_fs_ids,
                currentCompProjectId,
              })
          ) {
            dispatch(
              fetchBSData(project_ids, browse_fs_ids, currentCompProjectId)
            );
          }
          if (
            !pnlStatement.isSuccess ||
            pnlStatement.set_payload !==
              JSON.stringify({
                project_ids,
                browse_fs_ids,
                currentCompProjectId,
              })
          ) {
            dispatch(
              fetchPNLData(project_ids, browse_fs_ids, currentCompProjectId)
            );
          }
          const payload = {
            project_ids: project_ids,
            browse_fs_ids: browse_fs_ids,
            current_comp_project_id: currentCompProjectId,
          };
          if (
            !getSocie.isSuccess ||
            getSocie.payload !== JSON.stringify(payload)
          ) {
            dispatch(fetchSocieData(payload));
          }
        } 

        if (allAssociateCheckData.length > 0) {
          const project_ids = allAssociateCheckData.map((data) => data.project_id);
          const browse_fs = allAssociateCheckData.map((data) => FindIr(data.Ir_id));
          const browse_fs_ids = browse_fs.map(
            (data) => data[0]?.browse_control_dateFS
          );
          if (
            !associateStatement.isSuccess ||
            associateStatement.set_payload !==
              JSON.stringify({
                project_ids,
                browse_fs_ids,
                currentCompProjectId,
              })
          ) {
            dispatch(
              fetchAssociateData(project_ids, browse_fs_ids, currentCompProjectId)
            );
          }
        }
      }
    })();
  }, [allcheckdata, allIrList]);

  useEffect(() => {
    if (projectPreset) {
      getProjectName(projectPreset);
    }
  }, [projectPreset, projectsData]);

  const getCompanyData = async () => {
    try {
      const response = await getProjects();
      setProjectsData(response.data.project);
    } catch (err) {
      console.log("err", err.response);
    }
  };

  const getProjectName = async (p_id) => {
    try {
      const project = projectsData.find((data) => data._id === p_id);
      setSelectedPresetProject({
        project_name: project?.project_name,
        project_id: project?._id,
      });
    } catch (error) {
      console.log("error", error.message);
      return "None";
    }
  };
  const fetchPreConsole = async () => {
    try {
      const response = await getPrepareConsole(id.id);
      if (response.status === 200) {
        dispatch(fetchPCData(id.id))
        setConsolData(response.data.getOnePreCompany[0]);
        setProjectPreset(response.data.getOnePreCompany[0]?.upload_preset);
        setConsolidate(response.data.getOnePreCompany[0]?.consolidation);
        setAssociateConsolidate(response?.data?.getOnePreCompany[0]?.AssociateConsolidate)
      }
    } catch (err) {
      console.log("err", err.response);
    }
  };

  const PopulateFS=()=>{
    history.push(`/populate-fs/${id.id}`);
    window.location.reload();

  }

  const CompanyName = (c_id, field) => {
    const result = allCompany.filter((adj) => adj._id === c_id);
    if (field === "parent") {
      return result[0]?.company_name ? result[0]?.company_name : "-";
    } else {
      return result[0]?.company_name ? result[0]?.company_name : "-";
    }
  };

  return (
    <>
      <div>
        <div className="page-wrapper compact-wrapper" id="pageWrapper">
        <Navbar />
          <div style={header}>
            <div style={name}>
              <div
                style={backNav}
              >
                <img src={arrowLeftGreen} style={{ marginRight: "10px" }} alt="arrow left" />
              </div>
            </div>
            <div style={line}></div>
            <div style={mainTag}>
              <input
                placeholder="Consolidated Financial Statement"
                style={{ border: "0", width: "20rem" }}
              />
            </div>
            <div style={button3} title="units conversion of the tb amount">
              <Select
                placeholder=""
                as="select"
                isClearable
                defaultInputValue="Units"
                theme={(theme) => ({
                  ...theme,
                  borderRadius: "6px",
                  cursor: "pointer",
                  colors: {
                    ...theme.colors,
                    backgroundColor: "white",
                    primary25: "#03565a98",
                    primary: "#03565A",
                  },
                  spacing: {
                    ...theme.spacing,
                    controlHeight: 50,
                  },
                })}
              />
            </div>
            <div style={button2}>
              <Select
                placeholder="0"
                as="select"
                isClearable
                defaultInputValue="2"
              />
            </div>
            <div style={button}>
              <div onClick={PopulateFS}>
                <Submit value="Populate FS" sm />
              </div>
            </div>
            <div style={button}>
              <div>
                <Submit disable={true} value="PFS Report" sm />
              </div>
            </div>
          </div>

          <div className="card-body">
            <div className="col-sm-12">
              {balanceSheet.loading ? (
                <CircularProgress disableShrink />
              ) : balanceSheet.isSuccess ? (
                <div className="card">
                  <div style={{ maxHeight: "77vh", overflowY: "scroll" }}>
                    <div style={{ minHeight: "77vh", width: "100vw" }}>
                      {balanceSheet.isSuccess ? (
                        <BSModalView
                          balanceSheetData={balanceSheet}
                          pnlStatementData={pnlStatement}
                          groupingData={groupingData}
                          filterData={filterData}
                          consoleData={consoldata}
                          allcheckdata={ allcheckdata}
                          currentCompProjectId={currentCompProjectId}
                          consoleNotes={notes}
                          id={id.id}
                          allIrListdata={allIrList}
                          allAssociateCheckData={allAssociateCheckData}
                          associateStatement={associateStatement}
                          CompanyName={CompanyName}
                          FindIr={FindIr}
                        />
                      ) : (
                        "-------"
                      )}
                    </div>
                  </div>
                </div>
              ) : (
                <p className="text-danger">{balanceSheet.error_message}</p>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default ViewFS;
