import React from 'react';
import { Checkbox } from "@mui/material";
import { useHistory } from 'react-router-dom';
import { Button } from "react-bootstrap";
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { useParams } from 'react-router';
import { softDeleteLedger } from '../../Services/ApiHandler';
import swal from 'sweetalert';
import { getBCTbRows, getDeletedLedgersRows, setIsDeletedLedgerModal, setSelectedFlatRows } from '../../../redux/actions/BookClosure/actionBookClosure';
import DeletedLedgerModal from './DeletedLedgerModal';

const TbcHeader = () => {
    const history = useHistory();
    const dispatch = useDispatch();
    const { bookClosureTbId, workspaceId } = useParams();

    const {
        selectedFlatRows
    } = useSelector((initialState) => initialState.BookClosureTbcReducer);

    const HandleDeleteLedgers = () => {
        if (selectedFlatRows.length <= 0) {
            toast.warning("Please Select Any Ledger Foe Delete.")
            return;
        }

        if (selectedFlatRows.length > 1) {
            toast.warning("Multiple delete ledgers not allowed..")
            return;
        }

        softDeleteLedger(bookClosureTbId, {
            ledgerID: selectedFlatRows[0]._id
        }).then(response => {
            toast.success("Ledger deleted successfully.");
            dispatch(getBCTbRows(bookClosureTbId));
            dispatch(setSelectedFlatRows([]));
        }).catch(error => {
            swal("Error", error?.response?.data?.error || "Soft delete ledger failed", "error")
        })
    }

    return (
        <>
            <div className="conventionalMenu" style={{ zIndex: "9" }}>
                <div
                    className="delete"
                    onClick={HandleDeleteLedgers}
                >
                    <p>Delete record</p>
                </div>

                <div
                    className="switchMode"
                    onClick={() => { }}
                >
                    <p>Switch Mode</p>
                </div>
                <div
                    className="filter"
                    onClick={() => { }}
                >
                    <p>Filter</p>
                </div>

                <div
                    className="deletedItem cursor-pointer"
                    onClick={() => { 
                        dispatch(getDeletedLedgersRows(bookClosureTbId));
                        dispatch(setIsDeletedLedgerModal(true)); 
                    }}
                >
                    <p>Deleted items</p>
                </div>

                <div
                    className="adjustment"
                    onClick={() => { }}
                >
                    <p>Adjustment</p>
                </div>

                <div className="checkYearly" >
                    <Checkbox
                        label={"Split mapping"}
                        sx={{
                            '&.Mui-checked': {
                                color: '#03565a',
                            }
                        }}
                        onChange={(e) => {
                        }}
                        inputProps={{ "aria-label": "controlled" }}
                    />
                    <p>Split mapping</p>
                </div>
                <div className="uploadConventional">
                    <div className="addTb" >
                        <h4>Upload trial balance</h4>
                    </div>
                    <div>
                        <Button className="custom-common-theme-bg-color text-white font-size-16 custom-font-600 font-sans" variant="contained" onClick={() => history.push(`/bookclosure/${workspaceId}`)} style={{ width: "200px", borderRadius: "5px" }}>Confirm Grouping</Button>
                    </div>
                </div>
            </div>

            <DeletedLedgerModal />
        </>
    )
}

export default TbcHeader