import { getAllPreset, getDeletedLedgers, getEntries, getMatrix, getTbDetails } from "../../../Bookclosure/Services/ApiHandler";

export const setBCCompany = (payload) => {
    return dispatch => {
        dispatch({
            type: "SET_BC_COMPANY",
            payload: payload
        })
    }
}

export const getBCTbRows = (bookClosureTbId) => {
    return dispatch => {
        dispatch({ type: "TBC_DATA_ACTION_START" })
        getTbDetails(bookClosureTbId).then((response) => {
            dispatch({
                type: "TBC_DATA_FETCH_DATA",
                payload: response.data
            })
            const groupings = response.data?.bookClosureTB?.grouping
            dispatch(filterByCategory({ groupings, type: "BS" }))
        }).catch((error) => {
            dispatch({
                type: "TBC_DATA_FETCH_DATA_FAILED",
                payload: error?.response?.data?.error
            })
        });
    }
}

export const setTabValue = (payload) => {
    return dispatch => {
        dispatch({
            type: "SET_TAB_VALUE",
            payload: payload
        })
    }
}

export const filterByCategory = (payload) => {
    const { groupings, type } = payload;
    const categoryItems = groupings.filter(item => item.category === type);

    return dispatch => {
        dispatch({
            type: "CATEGORY_ITEMS",
            payload: categoryItems
        })
    }
}

export const singleNotesData = (payload) => {
    const { subGrp, groupCatName } = payload;

    return dispatch => {
        dispatch({
            type: "CATEGORY_ITEMS_BY_NOTE_NO",
            payload: { ...subGrp, groupCatName }
        })
    }
}

export const getBCEntries = (bookClosureId, companyId) => {
    return dispatch => {
        dispatch({ type: "BC_ENTRIES_ACTION_START" })
        getEntries(bookClosureId, companyId).then((response) => {
            dispatch({
                type: "BC_ENTRIES_FETCH_DATA",
                payload: response.data
            })
        }).catch((error) => {
            dispatch({
                type: "BC_ENTRIES_FETCH_DATA_FAILED",
                payload: error?.response?.data?.error
            })
        });
    }
}

export const getBCMatrix = (bookClosureId, companyId) => {
    return dispatch => {
        dispatch({ type: "BC_MATRIX_ACTION_START" })
        getMatrix(bookClosureId, companyId).then((response) => {
            dispatch({
                type: "BC_MATRIX_FETCH_DATA",
                payload: response.data
            })
        }).catch((error) => {
            dispatch({
                type: "BC_MATRIX_FETCH_DATA_FAILED",
                payload: error?.response?.data?.error
            })
        });
    }
}

export const getLedgersViewMoreTask = (payload) => {
    return dispatch => {
        dispatch({ type: "SET_VIEW_MORE_LEDGERS", payload });
    }
}

export const clearLedgersViewMoreTask = () => {
    return dispatch => {
        dispatch({ type: "CLEAR_VIEW_MORE_LEDGERS" });
    }
}

export const setIsAddToTackModalOpen = (payload) => {
    return dispatch => {
        dispatch({
            type: "SET_ADD_TO_TASK_MODAL_OPEN",
            payload: payload
        })
    }
}

export const setIsEditLedgerModal = (payload) => {
    return dispatch => {
        dispatch({
            type: "SET_IS_EDIT_LEDGER_TO_TASK_MODAL",
            payload: payload
        })
    }
}

export const setAddedLedgers = (payload) => {
    return dispatch => {
        dispatch({
            type: "SET_ADDED_LEDGERS",
            payload: payload
        })
    }
}

export const setAdjustments = (payload) => {
    return dispatch => {
        dispatch({
            type: "SET_ADJUSTMENTS",
            payload: payload
        })
    }
}

export const setNewAdjustmentToTable = (payload) => {
    const adjObj = {
        date: "",
        addedLedgers: [
            {
                ledgerCode: "",
                adjustmentAmount: 0,
            },
            {
                ledgerCode: "",
                adjustmentAmount: 0,
            },
        ],
        remarks: "",
    }
    const payloadArr = payload ? payload : [];
    return dispatch => {
        dispatch({
            type: "SET_ADJUSTMENTS",
            payload: [...payloadArr, adjObj]
        })
    }
}

export const setOnchangeAdj = (ax, lx, target, payload) => {
    const { name, value } = target;

    if (lx !== null && lx !== undefined) {
        payload[ax]["addedLedgers"][lx][name] = value;
    } else {
        payload[ax][name] = value;
    }

    return dispatch => {
        dispatch({
            type: "SET_ADJUSTMENTS",
            payload: [...payload],
        });
    };
};

export const setTaskComments = (payload) => {
    return dispatch => {
        dispatch({
            type: "SET_TASK_COMMENTS",
            payload: payload
        })
    }
}

export const setIsAddSubGroupModal = (payload) => {
    return dispatch => {
        dispatch({
            type: "SET_IS_ADD_SUB_GROUP_MODAL",
            payload: payload
        })
    }
}
export const setIsAddSubGroupLabel3Modal = (payload) => {
    return dispatch => {
        dispatch({
            type: "SET_IS_ADD_SUB_GROUP_LABEL3_MODAL",
            payload: payload
        })
    }
}
export const setSelectedFlatRows = (payload) => {
    return dispatch => {
        dispatch({
            type: "SET_SELECTED_FLAT_ROWS",
            payload: payload
        })
    }
}
export const setIsDeletedLedgerModal = (payload) => {
    return dispatch => {
        dispatch({
            type: "SET_IS_DELETED_LEDGER_MODAL",
            payload: payload
        })
    }
}

export const getDeletedLedgersRows = (bookClosureTbId) => {
    return dispatch => {
        dispatch({ type: "DL_DATA_ACTION_START" })
        getDeletedLedgers(bookClosureTbId).then((response) => {
            dispatch({
                type: "DL_DATA_FETCH_DATA",
                payload: response.data
            })
        }).catch((error) => {
            dispatch({
                type: "DL_DATA_FETCH_DATA_FAILED",
                payload: error?.response?.data?.error
            })
        });
    }
}

export const getBCPresets = (page) => {
    return dispatch => {
        dispatch({ type: "BC_PRESET_ACTION_START" })
        getAllPreset(page).then((response) => {
            dispatch({
                type: "BC_PRESET_FETCH_DATA",
                payload: response.data
            })
        }).catch((error) => {
            dispatch({
                type: "BC_PRESET_FETCH_DATA_FAILED",
                payload: error?.response?.data?.error
            })
        });
    }
}